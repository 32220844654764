// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const FacebookIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="64px" height="64px" viewBox="0 0 64 64" {...props}>
      <path d="M39.8 12.2H48V0h-9.7C26.6.5 24.2 7.1 24 14v6.1h-8V32h8v32h12V32h9.9l1.9-11.9H36v-3.7a3.962 3.962 0 0 1 3.8-4.2z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(FacebookIcon);
