// @flow
import * as React from 'react';
import find from 'lodash/find';

import { i18n } from '../../../i18n';
import Link from '../../../i18n/components/Link';
import resolvePath, { type ResolvePathNode } from '../../helpers/resolvePath';

type LinkProps = React.ElementConfig<typeof Link>;

export type Props = {
  ...$Exact<$Diff<LinkProps, { to: any, localized: any }>>,
  node: $Shape<{ [any]: any, ...$Exact<ResolvePathNode> }>,
};

export type State = {
  error: ?Error,
};

const NodeLinkInner = ({ node, ...props }: Props) => {
  const locale = find(i18n.getLocales(), { prismicLocale: node.lang });

  return (
    <Link
      {...props}
      localized={false}
      to={locale ? resolvePath({ locale, node }) : '#'}
    />
  );
};

export default NodeLinkInner;
