// @flow
import type { PrismicStructuredTextType } from '~schema';

export default function hasRichTextFieldValue(
  richTextField?: ?PrismicStructuredTextType,
) {
  return !!(
    richTextField &&
    richTextField.text !== '' &&
    typeof richTextField.html === 'string' &&
    richTextField.html !== '<p></p>'
  );
}
