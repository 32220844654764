// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-templates-prismic-preview-page-jsx": () => import("./../src/pages-templates/PrismicPreviewPage.jsx" /* webpackChunkName: "component---src-pages-templates-prismic-preview-page-jsx" */),
  "component---src-pages-templates-home-page-jsx": () => import("./../src/pages-templates/HomePage.jsx" /* webpackChunkName: "component---src-pages-templates-home-page-jsx" */),
  "component---src-pages-templates-contact-page-jsx": () => import("./../src/pages-templates/ContactPage.jsx" /* webpackChunkName: "component---src-pages-templates-contact-page-jsx" */),
  "component---src-pages-templates-privacy-policy-page-jsx": () => import("./../src/pages-templates/PrivacyPolicyPage.jsx" /* webpackChunkName: "component---src-pages-templates-privacy-policy-page-jsx" */),
  "component---src-pages-templates-cookies-page-jsx": () => import("./../src/pages-templates/CookiesPage.jsx" /* webpackChunkName: "component---src-pages-templates-cookies-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

