// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: ({ direction }) => ({
    flexDirection: direction === 'vertical' ? 'column' : 'row',
  }),
  gridItem: {},
  link: ({ growLinks }) => ({
    width: growLinks ? '100%' : undefined,
  }),
});

export default styles;
