// @flow
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { parsePath } from 'gatsby';
import withStyles, { type WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MUICookieConsent from 'material-ui-cookie-consent';
import classnames from 'classnames';

import usePageContext from '../../page-context/hooks/usePageContext';
import RichText from '../../prismic/components/RichText';
import NodeLink from '../../prismic/components/NodeLink';
import usePrismicCookies from '../hooks/usePrismicCookies';
import cookies$config from '../../../../config/prismic-cookies';
import * as consentTypes from '../config/consentTypes';
import getCookieConsentComponentType from '../helpers/getCookieConsentComponentType';

const styles = {
  root: {},
  message: {},
  accept: {},
};

type MUICookiesConsentProps = React.ElementConfig<typeof MUICookieConsent>;

export type Color = $NonMaybeType<
  $ElementType<React.ElementConfig<typeof Typography>, 'color'>,
>;

export type ClassKey = $Keys<typeof styles>;

export type Props = {
  ...$Exact<$Diff<MUICookiesConsentProps, { cookieName: any }>>,
  ...$Exact<WithStyles<typeof styles>>,
  buttonColor?: Color,
  messageColor?: Color,
  className?: string,
  classes: { [ClassKey]: string },
  showReadMore?: boolean,
};

const CookieConsent = ({
  buttonColor,
  messageColor,
  className,
  classes,
  showReadMore,
  ...props
}: Props) => {
  const cookies = usePrismicCookies();
  const pageContext = usePageContext();
  const { pathname } = parsePath(pageContext.originalPath);
  const data = cookies && cookies.data;

  const consentType =
    cookies$config.componentTypeByPath?.[pathname] ||
    pageContext.cookieConsentComponentType ||
    cookies?.data?.consent_type;

  if (
    !cookies ||
    !data ||
    !consentType ||
    consentType === consentTypes.DISABLED
  ) {
    return null;
  }

  const componentType = getCookieConsentComponentType(consentType);

  return (
    <MUICookieConsent
      snackbarAnchor={{ horizontal: 'right', vertical: 'bottom' }}
      actions={[
        showReadMore && data.consent_button_read_more?.text ? (
          <Button
            component={NodeLink}
            node={cookies}
            color="inherit"
            key="readMore"
          >
            {data.consent_button_read_more?.text}
          </Button>
        ) : null,
      ]}
      {...props}
      componentType={componentType}
      className={classnames(classes.root, className)}
      cookieName={cookies$config.cookieName}
      title={data.consent_title?.text}
      message={
        <RichText
          {...data.consent_description}
          color={messageColor}
          className={classes.message}
        />
      }
      acceptButtonLabel={
        data.consent_button_accept?.text ? (
          <Typography
            color={buttonColor}
            variant="button"
            className={classes.accept}
          >
            {data.consent_button_accept?.text}
          </Typography>
        ) : null
      }
    />
  );
};

CookieConsent.defaultProps = {
  buttonColor: undefined,
  messageColor: undefined,
  className: undefined,
  showReadMore: undefined,
};

export default withStyles<ClassKey, *, Props>(styles)(CookieConsent);
