// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';

import FieldLink from '~plugins/prismic/components/FieldLink';

import type { Props } from './types';
import styles from './styles';
import NavigationLink from './NavigationLink';

const NavigationLinks = ({
  direction,
  component,
  LinkComponent = FieldLink,
  GridItemProps,
  growLinks,
  className,
  size,
  variant,
  color,
  classes,
  links,
  onClick,
  ...props
}: Props): React.Node => {
  return (
    <Grid className={classnames(classes.root, className)} {...props} container>
      {map(links, (item, index: number) =>
        item && item.action_label && item.action_label.text ? (
          <Grid {...GridItemProps} item key={index}>
            <NavigationLink
              component={LinkComponent}
              field={item.action_link}
              onClick={onClick}
              size={size}
              variant={variant}
              color={color}
              title={item.action_label.text}
              className={classes.link}
            >
              {item.action_label.text}
            </NavigationLink>
          </Grid>
        ) : null,
      )}
    </Grid>
  );
};

NavigationLinks.defaultProps = {
  className: undefined,
  spacing: 1,
};

export default withStyles<*, *, Props>(styles)(NavigationLinks);
