// @flow

import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import classnames from 'classnames';

import type { Props } from './types';
import styles from './styles';

import useSiteInfo from '~hooks/useSiteInfo';
import Link from '~plugins/i18n/components/Link';
import QuanticIcon from '~components/icons/QuanticIcon';
import NavigationLinks from '~components/NavigationLinks';

const AppBar = ({
  toggleLeftDrawer,
  toggleRightDrawer,
  classes,
  className,
  pulsatingMenu,
  ...props
}: Props) => {
  const siteInfo = useSiteInfo();

  return (
    <Toolbar
      component={Container}
      maxWidth="xl"
      className={classnames(className, classes.root)}
      {...props}
    >
      <div className={classes.wrapper}>
        <Button
          component={Link}
          to="/"
          disableRipple
          aria-label="Quantic Icon"
          className={classes.siteIcon}
        >
          <QuanticIcon className={classes.icon} />
        </Button>
        <Hidden xsDown implementation="css">
          <div className={classes.actions}>
            <NavigationLinks
              links={siteInfo?.data?.appbar_actions}
              direction="horizontal"
              variant="text"
              color="primary"
              classes={React.useMemo(
                () => ({
                  root: classes.appbarLinks,
                  link: classes.appbarLink,
                }),
                [classes],
              )}
            />
          </div>
        </Hidden>
      </div>
    </Toolbar>
  );
};

AppBar.defaultProps = {
  className: undefined,
  color: undefined,
  pulsatingMenu: false,
};

export default withStyles<*, *, *>(styles)(AppBar);
