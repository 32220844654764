// @flow
import * as React from 'react';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';

import type { Query } from '~schema';

import usePreviewContext from './usePreviewContext';

export default function usePreviewData(staticData: ?Query) {
  const previewData = usePreviewContext();

  return React.useMemo(
    () =>
      staticData || previewData
        ? (mergePrismicPreviewData<Query>({ staticData, previewData }): Query)
        : undefined,
    [staticData, previewData],
  );
}
