// @flow
import * as React from 'react';

import useSiteInfo from '~hooks/useSiteInfo';
import type { PrismicStructuredTextType } from '~schema';

export default function useFooterDescription() {
  const siteInfo = useSiteInfo();
  const rawDescription =
    (siteInfo && siteInfo.data && siteInfo.data.footer_description) ||
    undefined;

  const replacements = [
    [/{currentYear}/g, new Date().getFullYear().toString()],
  ];

  return React.useMemo(
    () =>
      replacements.reduce<PrismicStructuredTextType>(
        (acc, [regExp, value]) => ({
          ...acc,
          text: acc.text && acc.text.replace(regExp, value),
          html: acc.html && acc.html.replace(regExp, value),
        }),
        { ...rawDescription },
      ),
    [rawDescription],
  );
}
