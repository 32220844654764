// @flow

const { i18n } = require('../../src/plugins/i18n');

/* GatsbyStarterConfig: locales
Here you can define the locales for the app.
*/
i18n.addLocales([
  {
    id: 'en',
    pathPrefix: 'en',
    name: 'English',
    prismicLocale: 'en-gb',
    tagIETF: 'en_GB',
  },
]);

/* GatsbyStarterConfig: default locale
Here you can specify the default locale for the app. You can
also omit this configuration, and the first locale in the locales
list will be taken as the default.
*/
// i18n.setDefaultLocale('en');
