// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import SocialLinks from '~components/SocialLinks';
import NavigationLinks from '~components/NavigationLinks';
import QuanticIcon from '~components/icons/QuanticIcon';
import useSiteInfo from '~hooks/useSiteInfo';

import type { Props } from './types';
import styles from './styles';
import useFooterDescription from './hooks/useFooterDescription';

const Footer = ({ className, classes, ...props }: Props): React.Node => {
  const siteInfo = useSiteInfo();
  const copyrightDescription = useFooterDescription();
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Container maxWidth="xl" className={classes.wrapper}>
        <div className={classes.actions}>
          <QuanticIcon className={classes.siteIcon} />
        </div>
        <div className={classes.contact}>
          <Hidden xsDown implementation="css">
            <Typography variant="subtitle2" className={classes.socialTitle}>
              Contact us
            </Typography>
          </Hidden>
          <SocialLinks
            links={siteInfo?.data?.social_links}
            classes={React.useMemo(
              () => ({
                root: classes.socialLinks,
                iconButton: classes.socialLink,
              }),
              [classes],
            )}
          />
        </div>
      </Container>
      <div className={classes.copyright}>
        <div>
          <RichText
            {...copyrightDescription}
            className={classes.copyrightText}
          />
        </div>
        <NavigationLinks
          links={siteInfo?.data?.footer_actions}
          direction="horizontal"
          variant="text"
          color="secondary"
          classes={React.useMemo(
            () => ({
              root: classes.navigationLinks,
              link: classes.navigationLink,
            }),
            [classes],
          )}
        />
      </div>
    </div>
  );
};

Footer.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(Footer);
