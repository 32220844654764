// @flow

  /* GatsbyStarterConfig: prismic content types */
exports['404_page'] = ('404_page': '404_page');
exports.contact = ('contact': 'contact');
exports.cookies = ('cookies': 'cookies');
exports.home_page = ('home_page': 'home_page');
exports.privacy_policy = ('privacy_policy': 'privacy_policy');
exports.site_info = ('site_info': 'site_info');
exports.organization = ('organization': 'organization');
exports.author = ('author': 'author');
