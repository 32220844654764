// @flow

import * as React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import smoothscroll from 'smoothscroll-polyfill'; // Polyfill required for scrollIntoView animated.

import theme from '../../config/material-ui/theme';
import AppIntlProvider from '~plugins/i18n/components/AppIntlProvider';
import AppHelmet from '~plugins/helmet/components/AppHelmet';
import PreviewProvider from '~plugins/prismic/components/PreviewProvider';
import Snackbar from '~components/Snackbar';
import SnackbarProvider from '~plugins/material-ui/modules/snackbar/components/SnackbarProvider';
import PageContext, { type Page$Context } from '~plugins/page-context';
import BaseLayout from './BaseLayout';
import '@babel/polyfill';
// Importing custom fonts here.
import '../../config/material-ui/fonts/fonts.css';

export type Props = {
  children: React.Node,
  pageContext: Page$Context,
};
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const Layout = ({ children, ...props }: Props) => {
  return (
    <AppIntlProvider {...props}>
      <ThemeProvider theme={theme}>
        <PreviewProvider>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <PageContext.Provider value={props.pageContext}>
            <SnackbarProvider Snackbar={Snackbar}>
              <AppHelmet {...props}>
                <link
                  rel="preload"
                  as="font"
                  href="/fonts/Hibiscus-glitch.woff"
                  type="font/woff"
                  crossOrigin="anonymous"
                />
                <link
                  rel="preload"
                  as="font"
                  href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap"
                />
                <link rel="preconnect" href="https://images.prismic.io" />
                <link rel="dns-prefetch" href="https://images.prismic.io" />
              </AppHelmet>
              <BaseLayout>{children}</BaseLayout>
            </SnackbarProvider>
          </PageContext.Provider>
          <CssBaseline />
        </PreviewProvider>
      </ThemeProvider>
    </AppIntlProvider>
  );
};

export default Layout;
