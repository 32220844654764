// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import MaterialUILayout from 'material-ui-layout';
import BasicDrawer from 'material-ui-layout/lib/templates/Drawer/BasicDrawer';

import AppBar from '~components/AppBar';
import Footer from '~components/Footer';
import CookieConsent from '~plugins/prismic-cookies/components/CookieConsent';

export type ClassKey =
  | 'layoutMain'
  | 'layoutAppBar'
  | 'layoutDrawer'
  | 'layoutFooter'
  | 'cookieConsentAccept';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  children: React.Node,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const styles: Styles = theme => ({
  layoutMain: {
    marginTop: 0,
  },
  layoutAppBar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    borderBottom: '0.5px solid #474747',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  layoutDrawer: {
    display: 'none',
  },
  layoutFooter: {
    width: '100%',
    color: '#eb0e81',
    paddingBottom: theme.spacing(1),
    borderTop: '0.5px solid #474747',
  },
  cookieConsentAccept: {
    color: theme.palette.secondary.dark,
  },
});

const BaseLayout = ({ classes, children }: Props) => (
  <>
    <MaterialUILayout
      mainGrow={false}
      stickyFooter
      appBarContent={<AppBar />}
      footerContent={<Footer />}
      drawerContent={<BasicDrawer />}
      classes={React.useMemo(
        () => ({
          main: classes.layoutMain,
          appBar: classes.layoutAppBar,
          drawerPaper: classes.layoutDrawer,
          footer: classes.layoutFooter,
        }),
        [classes],
      )}
    >
      {children}
    </MaterialUILayout>
    <CookieConsent
      showReadMore
      classes={{
        accept: classes.cookieConsentAccept,
      }}
    />
  </>
);

export default withStyles<*, *, Props>(styles)(BaseLayout);
