// @flow
import * as React from 'react';
import { Location as ReachRouterLocation } from '@reach/router';

import type { Query } from '~schema';

import getPreviewRawData from '../helpers/getPreviewRawData';
import PreviewContext from '../context/PreviewContext';

export type Props = {
  children: React.Node,
};

const PreviewProviderInner = ({
  location,
  ...props
}: { location: Location } & Props) => {
  const previewDataRaw = getPreviewRawData(location);
  const isPreview = !!previewDataRaw;
  const previewData = React.useMemo<Query | void>(
    () =>
      typeof previewDataRaw === 'string'
        ? JSON.parse(previewDataRaw)
        : undefined,
    [previewDataRaw],
  );

  return (
    <PreviewContext.Provider
      {...props}
      value={React.useMemo(() => (isPreview ? previewData : undefined), [
        isPreview,
        previewData,
      ])}
    />
  );
};

const PreviewProvider = (props: Props) => (
  <ReachRouterLocation>
    {({ location }) => <PreviewProviderInner {...props} location={location} />}
  </ReachRouterLocation>
);

export default PreviewProvider;
