// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  siteIcon: {},
  icon: {
    width: 'auto',
    height: 25,
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      height: 20,
    },
  },
  actions: {},
  appbarLinks: {},
  appbarLink: {
    minWidth: 113,
  },
  menuIconButton: {},
  menuIcon: {},
});

export default styles;
