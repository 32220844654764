// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button, {
  type ButtonProps,
  type ButtonClassKey,
} from '@material-ui/core/Button';

export type BaseProps = ButtonProps<'button', {}>;

export type ClassKey = ButtonClassKey;

export type Color = $ElementType<BaseProps, 'color'>;

export type Variant = $ElementType<BaseProps, 'variant'>;

export type Props = {
  ...$Exact<BaseProps>,
  ...$Exact<WithStyles<ClassKey>>,
  size?: $ElementType<BaseProps, 'size'>,
  variant?: Variant,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  // button class keys
  root: {},
  label: {},
  text: {
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textPrimary: {},
  textSecondary: {},
  outlined: {},
  outlinedPrimary: {},
  outlinedSecondary: {},
  contained: {},
  containedPrimary: {},
  containedSecondary: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
});

const NavigationLink = ({ ...props }: Props): React.Node => {
  return <Button {...props} />;
};

NavigationLink.defaultProps = {
  className: undefined,
  size: undefined,
  variant: undefined,
};

export default withStyles<*, *, Props>(styles)(NavigationLink);
