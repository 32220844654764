// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicSiteInfo } from '~schema';

const query = graphql`
  query UseSiteInfoQuery {
    allPrismicSiteInfo {
      nodes {
        id
        lang
        data {
          appbar_actions {
            action_label {
              text
            }
            action_link {
              link_type
              url
            }
            action_variant
          }
          footer_description {
            text
            html
          }
          footer_actions {
            action_label {
              text
            }
            action_link {
              link_type
              url
            }
          }
          social_links {
            social_icon
            social_label {
              text
            }
            social_link {
              link_type
              url
            }
          }
        }
      }
    }
  }
`;

export default function useSiteInfo(): ?PrismicSiteInfo {
  return useFindCurrentLocaleNode<PrismicSiteInfo>({
    nodes: useStaticQuery<Query>(query).allPrismicSiteInfo?.nodes,
    toQuery: node => ({ prismicSiteInfo: node }),
    fromQuery: data => data?.prismicSiteInfo,
  });
}
