// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const QuanticIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="166px" height="26px" viewBox="0 0 166 26" {...props}>
      <g>
        <path d="M11.999 26c-1.604.007-3.19-.32-4.65-.959a11.815 11.815 0 01-3.94-2.825A12.33 12.33 0 01.87 18.202 12.86 12.86 0 010 13.498a12.97 12.97 0 01.87-4.704 12.232 12.232 0 012.537-4.026 11.936 11.936 0 013.933-2.812 11.791 11.791 0 019.301 0 11.968 11.968 0 013.927 2.812c4.575 4.99 4.575 12.477 0 17.467a12.697 12.697 0 01-1.993 1.7c-.625.432-1.292.803-1.993 1.106a11.53 11.53 0 01-4.584.959zm0-19.769a5.826 5.826 0 00-2.565.576c-.793.377-1.5.903-2.08 1.546a7.381 7.381 0 00-1.408 2.314 7.62 7.62 0 00-.525 2.831 7.727 7.727 0 001.934 5.113 6.613 6.613 0 002.08 1.566c.795.384 1.674.581 2.564.576a5.77 5.77 0 002.53-.576 6.875 6.875 0 002.1-1.566 7.305 7.305 0 001.409-2.313 7.791 7.791 0 00.504-2.819 7.883 7.883 0 00-.504-2.831 7.032 7.032 0 00-1.409-2.314 6.82 6.82 0 00-2.1-1.546 5.77 5.77 0 00-2.517-.55l-.013-.007z" />
        <path d="M21.034 26H28l-5.662-6L19 23.949zM50 17.565a8.013 8.013 0 01-.786 3.285 8.079 8.079 0 01-2.126 2.74c-.9.78-1.93 1.387-3.041 1.794a10.505 10.505 0 01-7.087 0 9.702 9.702 0 01-3.01-1.754 8 8 0 01-2.125-2.74A8.505 8.505 0 0131 17.604V0h5.27v17.532c.025.428.158.842.387 1.202.248.38.565.708.934.966.397.28.831.5 1.289.657a5.115 5.115 0 003.157 0 5.115 5.115 0 001.288-.657c.369-.258.686-.586.934-.966.229-.36.362-.774.387-1.202V.145H50v17.42zM56 26l6.624-25h5.553L75 26h-5.276L65.5 8.577 61.282 26zM102 .178V26h-5.276v-5.929L88.302 8.873V26H83V0h5.27l.103.145 8.319 11.067V.178zM127 6.22h-6.837V26h-5.287V6.22H108V1h19zM139 26h-6V1h6zM157.435 20.795a5.41 5.41 0 002.33-.505 6.077 6.077 0 002.019-1.535l3.836 3.453a11.405 11.405 0 01-3.747 2.827 10.661 10.661 0 01-8.863 0 10.993 10.993 0 01-3.735-2.807 12.719 12.719 0 01-2.425-3.979 13.613 13.613 0 010-9.472 12.86 12.86 0 012.431-4.01 10.993 10.993 0 013.735-2.808 10.662 10.662 0 019.135.14c1.495.741 2.81 1.807 3.849 3.122l-3.957 3.326a5.715 5.715 0 00-4.595-2.303 5.267 5.267 0 00-2.438.576 6.815 6.815 0 00-1.981 1.548 7.052 7.052 0 00-1.361 2.328 8.308 8.308 0 00-.481 2.84c-.008.96.155 1.912.48 2.814.304.85.761 1.635 1.35 2.315a6.473 6.473 0 001.98 1.567c.757.381 1.593.574 2.438.563z" />
      </g>
    </SvgIcon>
  );
};

export default React.memo<Props>(QuanticIcon);
