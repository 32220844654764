// @flow
import type { Styles } from './types';
import getPaddingFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actions: {},
  siteIcon: {
    width: 'auto',
    height: 25,
    color: theme.palette.common.white,
  },
  contact: {
    display: 'inherit',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  socialTitle: {
    '&::after': {
      content: JSON.stringify('|'),
      position: 'relative',
      height: 'auto',
      marginLeft: theme.spacing(1),
    },
  },
  socialLinks: {},
  socialLink: {
    transform: `translateX(${theme.spacing(-1)}px)`,
    color: 'inherit',
    [theme.breakpoints.up('sm')]: {
      transform: `translateY(${theme.spacing(-1)}px)`,
    },
  },
  copyright: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingRight: theme.spacing(getPaddingFactor(breakpoint)),
          paddingLeft: theme.spacing(getPaddingFactor(breakpoint)),
        },
      }),
      {},
    ),
  },
  copyrightText: {
    marginRight: theme.spacing(2),
    '& > *': {
      ...theme.typography.caption,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  navigationLinks: {
    width: 'auto',
    transform: `translateY(2px)`,
  },
  navigationLink: {
    color: 'inherit',
    fontSize: theme.typography.caption.fontSize,
  },
});

export default styles;
