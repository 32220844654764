// @flow
import type { PathResolver } from '../../src/plugins/prismic/types';
import type { PathResolvers, PathResolverByType } from './types';

const { oneLine } = require('common-tags');

const contentTypes = require('./contentTypes');
const selectPath = require('../../src/plugins/prismic/helpers/selectPath')
  .default;

/* GatsbyStarterConfig: paths for Prismic nodes */
const pathResolvers: PathResolvers = {
  '404_page': () => '/404',
  contact: ({ locale, doc }) => selectPath(locale, {}, `/${doc.slug}`),
  cookies: ({ locale, doc }) => selectPath(locale, {}, `/${doc.slug}`),
  home_page: () => '/',
  privacy_policy: ({ locale, doc }) => selectPath(locale, {}, `/${doc.slug}`),
  organization: () => '/',
  author: () => '/',
  site_info: () => '/',
};

const pathResolver: PathResolver = ({ locale }) => doc => {
  const resolvePath: ?PathResolverByType = pathResolvers[doc.type];

  if (!resolvePath) {
    if (contentTypes[doc.type]) {
      throw new Error(
        `expected a pathResolver function for prismic type ${doc.type}`,
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(oneLine`
        [config/prismic] No pathResolver function found for non-registered
        prismic type '${doc.type}'
      `);
    }
  }

  return resolvePath ? resolvePath({ locale, doc }) : '';
};

module.exports = pathResolver;
