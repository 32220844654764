// @flow
/* eslint-disable import/no-mutable-exports */
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import mediaQuery from 'css-mediaquery';

import { INITIAL_BREAKPOINT } from './constants';
import type { ThemeOptions } from './types';

const TitleFontFamily = ['Hibiscus-glitch', 'sans-serif'];
const bodyFontFamily = ['Work Sans', 'sans-serif'];

/* GatsbyStarterConfig: material-ui theme */
const palette = createPalette({
  primary: {
    main: '#FFF',
  },
  secondary: {
    main: '#e50e7e',
    light: '#f22691',
    dark: '#be0d6a',
  },
  background: {
    default: '#0E0E0E',
  },
});

let theme = createMuiTheme(
  ({
    palette,
    // $FlowFixMe --> Reason: font display
    typography: {
      h1: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
        fontSize: '9.5rem',
      },
      h2: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
      },
      h3: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
      },
      h4: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
      },
      h5: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 500,
      },
      h6: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
      },
      subtitle1: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 500,
      },
      subtitle2: {
        fontFamily: TitleFontFamily,
        fontDisplay: 'swap',
      },
      body1: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 400,
      },
      body2: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 400,
      },
      caption: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 500,
        fontSize: '0.625rem',
      },
      button: {
        fontFamily: TitleFontFamily,
        fontWeight: 'bold',
        fontDisplay: 'swap',
      },
      overline: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontWeight: 300,
      },
    },
    props: {
      MuiWithWidth: { initialWidth: INITIAL_BREAKPOINT },
      MuiUseMediaQuery: {
        ssrMatchMedia:
          typeof window === 'undefined'
            ? (query: string) => ({
                matches: mediaQuery.match(query, {
                  width: theme.breakpoints.values[INITIAL_BREAKPOINT],
                }),
              })
            : undefined,
      },
      BodySection: {
        paddingFactor: [10, 0],
        paddingAlterFactor: {
          xs: 1,
          sm: 1.25,
          md: 1.5,
        },
      },
    },
    overrides: {
      MuiFormControl: {
        root: {
          '&[hidden]': {
            display: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: '1rem',
        },
      },
      MuiInputBase: {
        root: {
          paddingTop: 2,
          paddingBottom: 2,
        },
        input: {
          padding: '6px 10px',
          color: palette.common.white,
          fontWeight: 500,
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          textTransform: 'lowercase',
          '&:hover': {
            textTransform: 'uppercase',
            color: palette.secondary.main,
            backgroundColor: 'transparent',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: palette.common.white,
        }
      }
    },
  }: ThemeOptions),
);

/* GatsbyStarterConfig: responsive font sizes */
theme = responsiveFontSizes(theme, {
  breakpoints: undefined,
  disableAlign: undefined,
  factor: undefined,
  variants: undefined,
});

export default theme;
