// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import map from 'lodash/map';

import FacebookIcon from '~components/icons/FacebookIcon';
import InstagramIcon from '~components/icons/InstagramIcon';
import LinkedInIcon from '~components/icons/LinkedInIcon';
import MailIcon from '~components/icons/MailIcon';
import TwitterIcon from '~components/icons/TwitterIcon';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicStructuredTextType, PrismicLinkType } from '~schema';

export type ClassKey = 'root' | 'iconButton' | 'icon';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  links: ?Array<?{
    [any]: any,
    social_label?: ?PrismicStructuredTextType,
    social_link?: ?PrismicLinkType,
    social_icon?: ?string,
  }>,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
  iconButton: {},
  icon: {
    width: 22,
    height: 22,
  },
});

const icons: { [string]: React.ComponentType<any> } = {
  Facebook: FacebookIcon,
  Twitter: TwitterIcon,
  Instagram: InstagramIcon,
  Email: MailIcon,
  LinkedIn: LinkedInIcon,
};

const SocialIcon = ({ icon, ...props }: { [any]: any, icon: string }) => {
  const Component = icons[icon];
  return Component ? <Component {...props} /> : null;
};

const SocialLinks = ({
  className,
  classes,
  links,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      {map(links, (link, index: number) =>
        link &&
        link.social_label &&
        link.social_icon &&
        icons[link.social_icon] ? (
          <IconButton
            key={index}
            component={FieldLink}
            field={link?.social_link}
            aria-label={link?.social_label?.text || link.social_icon}
            title={link?.social_label?.text}
            className={classes.iconButton}
          >
            <SocialIcon icon={link.social_icon} className={classes.icon} />
          </IconButton>
        ) : null,
      )}
    </div>
  );
};

SocialLinks.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(SocialLinks);
