// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '../../prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicCookies } from '~schema';

const query = graphql`
  query UsePrismicCookiesDefaultQuery {
    allPrismicCookies {
      nodes {
        id
        prismicId
        type
        lang
        slugs
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          meta_keywords {
            meta_keyword {
              text
            }
          }
          title {
            text
          }
          consent_type
          consent_title {
            text
          }
          consent_description {
            text
            html
          }
          consent_button_read_more {
            text
          }
          consent_button_accept {
            text
          }
          page_title {
            text
          }
          page_description {
            text
            html
          }
        }
      }
    }
  }
`;

export default function usePrismicCookies(): ?PrismicCookies {
  return useFindCurrentLocaleNode<PrismicCookies>({
    nodes: useStaticQuery<Query>(query).allPrismicCookies?.nodes,
    toQuery: prismicCookies => ({ prismicCookies }),
    fromQuery: data => data?.prismicCookies,
  });
}
